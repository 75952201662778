<template>
	<div>
		<div class="create-basket-info">
			<div class="create-basket-info__header">
				<h2 class="create-basket-info__title">Создайте коллективную корзину и заказывайте дешевле!</h2>
				<img
					@click="$emit('closeBasketInfo')"
					src="../../assets/img/close.svg"
					alt="Close"
					class="create-basket-info__close"
				>
			</div>
			<button
				class="btn btn-green create-basket-info__btn"
				@click="handleCreateBasket"
			>Создать
			</button>
			<img
				class="create-basket-info__cart"
				src="../../assets/img/info-cart.png"
				alt="Info"
			>
		</div>
		<CreateBasketFormPopup
			:visible="popups.createBasket"
			@openInvitePopup="togglePopup('inviteUser');"
			@closeForm="togglePopup('createBasket');"
		/>
		<ConfirmationPopup
			:visible="popups.inviteUser"
			:title="'Поделитесь корзиной с другими участниками'"
			:subtitle="'Отправьте ссылку или покажите QR-код другим участникам, чтобы они могли присоединиться к корзине'"
			@closePopup="togglePopup('inviteUser')"
		>
			<template #content>
				<div
					v-if="currentBasket?.inviteQr"
					class="confirmation-popup__qr"
					v-html="currentBasket?.inviteQr"
				/>
				<yandex-share
					class="create-basket-info_yandex-share"
					:services="['telegram', 'odnoklassniki', 'vkontakte','whatsapp','viber']"
					:url="currentBasket?.inviteLink"
					:big="true"
					:title="inviteLinkTitle"
				/>
			</template>
			<template #buttons>
				<button
					class="btn confirmation-popup__btn"
					@click="copyLink"
				>
					<img
						src="../../assets/img/icons/link.svg"
						alt="Link"
					>
					Поделиться ссылкой
				</button>
			</template>
		</ConfirmationPopup>
	</div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import {notification} from "@/utils";
import YandexShare from "@cookieseater/vue-yandex-share";

export default {
	name: "CreateBasketInfo",
	data()
	{
		return {
			popups: {
				createBasket: false,
				inviteUser: false
			},
		}
	},
	components: {
		YandexShare,
		CreateBasketFormPopup: () => import("@/components/popup/CreateBasketFormPopup.vue"),
		ConfirmationPopup: () => import("@/components/popup/ConfirmationPopup.vue"),
	},
	computed: {
		...mapGetters({
			currentBasket: 'getCurrentBasket',
			user: 'getUser',
		}),
		inviteLinkTitle()
		{
			const basketName = this.currentBasket?.name ? `«${this.currentBasket?.name}»` : '';
			const basketDate = this.currentBasket?.day ? `Дата ${this.currentBasket?.day}.` : '';
			const basketInitiator = this.currentBasket?.ownerName ? `Инициатор ${this.currentBasket?.ownerName}.` : '';

			return `Присоединяйтесь к коллективной корзине ${basketName}. ${basketDate} ${basketInitiator}`;
		}
	},
	methods: {
		...mapMutations({
			openPhoneAuthPopup: "openPhoneAuthPopup"
		}),
		togglePopup(value)
		{
			this.popups[value] = !this.popups[value];
		},
		copyLink()
		{
			if (!this.currentBasket?.inviteLink) return notification({
				title: 'Что-то пошло не так. Попробуйте ещё раз',
				type: 'error'
			});

			navigator.clipboard.writeText(this.currentBasket?.inviteLink);

			this.togglePopup('inviteUser');

			notification({title: 'Ссылка скопирована в буфер обмена'});
		},
		handleCreateBasket()
		{
			if (!this.user) return this.openPhoneAuthPopup();

			this.$emit('closeBasketInfo');
			this.togglePopup('createBasket');
		},
	}
}
</script>
<style lang="scss">
.create-basket-info
{
	display: flex;
	flex-direction: column;
	border-radius: 16px;
	padding: 12px 16px;
	gap: 16px;
	background-color: $textColor;
	width: 312px;

	position: relative;
}

.create-basket-info__header
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.create-basket-info_yandex-share
{
	.ya-share2__container
	{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ya-share2__badge
	{border-radius: 100% !important;}

	.ya-share2__icon
	{
		width: 48px !important;
		height: 48px !important;
		background-size: 36px !important;
		background-repeat: no-repeat;
		background-position: center;
	}

	.ya-share2__item
	{margin-right: 8px !important;}
}

.create-basket-info__title
{
	max-width: 240px;

	font-family: $mainFontBoldNew;
	font-size: 16px;
	line-height: 24px;
	color: white;
}

.create-basket-info__close
{cursor: pointer;}

.create-basket-info__btn
{
	font-size: 14px;
	line-height: 24px;
	padding: 8px 16px;
	max-width: 90px;
}

.create-basket-info__cart
{
	width: 120px;
	height: 120px;

	position: absolute;
	bottom: -18px;
	right: 16px;
}
</style>